$accent: #9e5500;

.App {
    width: 100%;
    overflow: auto;
    font-family: "Raleway", sans-serif;
    > div {
        max-width: 1200px;
        margin: 0 auto;
        &:not(.nav) {
            margin-top: 88px;
        }
        &.home {
            height: 80vh;
            max-height: 800px;
            @media screen and (min-width:768px){
                border-bottom: 1px solid #000;
            }
            
        }
    }

    h1 {
        font-family: "Della Respira", serif;
        color: $accent;
        margin: 0 22px 22px 22px;
        color: $accent;
        font-size: 48px;
    }

    h3{
        font-family: "Della Respira", serif;
        font-size: 26px;
        margin: 44px 0 22px 22px;
        font-weight: bold;

    }

    .back-btn{
        text-decoration: none;
        color: #666;
        margin: 0 0 44px 22px;
        display: inline-flex;
        height: 15px;
        svg{
            width: 15px;
            fill: #666;
            margin-right: 11px;
        }
    }
    @media screen and (min-width:768px){
        height: 100vh;
    }
}

.nav {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100vw;
    max-width: 1200px;
    left: 0;
    right: 0;
    background-color: #f4e9db;
    margin: 0 auto;
    padding: 11px 0;
    border-bottom: 1px solid #000;
    
    .desktop-nav, .mobile-nav{
        justify-content: space-between;
        align-items: center;
    }

    &.nav-home {
        justify-content: flex-end;
        .desktop-nav, .mobile-nav{
            justify-content: flex-end;
        }
    }

    

    ul {
        list-style: none;
        font-size: 18px;
        li {
            margin: 0 22px;
            a {
                color: #000;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .desktop-nav, .mobile-nav{
        > a {
            text-decoration: none;
            color: #000;
            font-size: 24px;
            font-family: "Della Respira", serif;
            margin-left: 22px;
            span {
                color: $accent;
            }
        }
    }



    .desktop-nav{
        display: none;
        width: 100%;
        min-height: 36px;
    
        ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        @media screen and (min-width: 768px){
            display: flex;
        }

    }

    .mobile-nav{
        display: flex;
        flex-wrap: wrap;
        button{
            background: none;
            border: none;
            svg{
                width: 25px;
            }
        }

        >a{
            text-align: left;
        }

        .mobile-drop{
            display: none;
            width: 100%;
            text-align: right;
            &.active{
                display: block;
            }
            ul{
                li{
                    border-bottom: 1px solid rgb(152, 152, 152);
                    &:last-child{
                        border-bottom: none;
                        margin-bottom: 0;
                    }
                    a{
                        width: 100%;
                        display: block;
                        padding: 11px 0;
                    }
                }
            }
        }
        @media screen and (min-width:768px){
            display: none;
        }
    }

}

.home {

    h1 {
        text-align: center;
        font-size: 60px;
        color: #000;
        span {
            color: $accent;
        }
    }

    button{
        img{
            filter: invert(1) !important;
        }
        @media screen and (max-width: 768px){
            position: relative;
            top: -50px !important;
            height: 50px;
        }
    }

    .rsis-container{
        overflow: hidden;
        > div{
            height: 100% !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            transition-timing-function: linear !important;
        }
        &::before, &::after{
            content: '';
        }
    }

    @media screen and (max-width: 768px){
        h1{
            margin-bottom: 88px;
            font-size: 48px;
        }
        > div{
            overflow: unset !important;
            >div{
                overflow: unset !important;
            }
        }
    }
}

.contact,
.about {

    p {
        margin: 0 22px;
        margin-top: 22px;
        line-height: 1.8rem;
        font-size: 18px;
        -webkit-font-feature-settings: "lnum";
        -moz-font-feature-settings: "lnum";
        font-feature-settings: "lnum";
        span {
            font-weight: bolder;
        }
        a{
            color: $accent;
        }
    }
}

.artists{
    h1{
        margin: 0 0 22px 22px;
        font-size: 40px;
        @media screen and (min-width:768px){
            font-size: 48px;
        }
    }
    > button{
        background: none;
        border: 1px solid #9e5500;
        margin: 0 0 22px 22px;
        font-family: 'raleway', sans-serif;
        font-weight: bold;
        border-radius: 3px;
        padding: 5px 11px;
        &:hover{
            cursor: pointer;
        }
    }
    .artist-list{
       margin: 22px 22px 0;

        > a{
            display: inline-block;
            margin: 0 22px 22px 0;
            font-size: 22px;
            color: black;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }

    .art-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media screen and (min-width:768px){
            justify-content: flex-start;
        }

        .artpiece{
            max-width: 255px;
            width: 100%;
            background-color: #fbf3e9;
            padding: 22px;
            margin: 0 22px 44px 22px;
            box-shadow: 4px 4px 3px -2px #00000037;
            text-decoration: none;
            transition: transform 0.1s linear;
            position: relative;

            &:hover{
                transform: scale(1.05);
            }
            img{
                height: 100%;
                max-height: 150px;
                margin: 0 auto;
                display: block;
            }

            h4{
                font-family: "Della Respira";
                font-size: 24px;
                margin-top: 11px;
                color: $accent;
                line-height: 1.7rem;
            }

            p{
                margin-top: 11px;
                color: black;
            }
            svg{
                width: 20px;
                position: absolute;
                right: 11px;
                bottom: 11px;
            }
        }
    }

}

.art-piece{
    h1{
        margin: 0 0 22px 22px;
        font-size: 40px;
        @media screen and (min-width:768px){
            font-size: 48px;
        }
    }
    .display-art{
        position: relative;
        .img-container{
            position: relative;
            width: fit-content;
            margin: 0 auto;
            
            img{
                width: 80%;
                margin: 44px auto 0;
                display: block;
                @media screen and (min-width:768px){
                    height: 100%;
                    max-height: 500px;
                    position: relative;
                    z-index: 2;
                }
            }

            .info-text{
                width: 80%;
                margin: 0 auto 44px;
                padding: 11px 22px;
                background-color: #fff8f0;
                border: 1px solid #7d6b57;
                p{
                    margin: 7px 0;
                }

                @media screen and (min-width:768px){
                   margin-top: 22px;
                }
            }
            span{
                display: block;
                width: 80%;
                margin: 0 auto;
                position: absolute;
                top: -30px;
                right: 10%;
                text-align: right;
                color: #666;
                @media screen and (min-width:768px){
                    width: 100%;
                }
            }
        }
    }
    .full-modal{
        position: fixed;
        z-index: 1000;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: #000;
        display: flex;
        flex-direction: column;
        overflow: auto;
        img{
            margin: 44px auto 0;
            display: block;
            width: 100%;
            @media screen and (min-width:768px){
                position: relative;
                z-index: 2;
                height: 100%;
                max-height: 700px;
                width: unset;
            }
            &.morezoom{
                max-height: unset;
                height: auto;
                width: 180%;
                @media screen and (min-width:768px){
                    width: 120%;
                }
            }
        }
        button{
            position: fixed;
            background: #fff;
            border: none;
            right: 0;
            bottom: 0;
            padding: 22px;
            z-index: 3;
            &:hover{
                cursor: pointer;
            }
            svg{
                fill: #000;
                width: 20px;
            }

        }
    }
}
